// Move to admin.js
// Entry point for the build script in your package.json
import "trix"
import "@rails/actiontext"
import Sortable from "sortablejs"
import Rails from "@rails/ujs"

Rails.start()

// Initialize sortable tables
document.addEventListener('DOMContentLoaded', () => {
  const sortable = document.querySelector('.sortable')
  if (!sortable) { return }

  const options = {
    onUpdate: (event) => {
      document.getElementById('insertion_position').value = parseInt(event.newIndex)
      document.getElementById('insertion_id').value = event.item.dataset.id
      document.getElementById('new_insertion').submit()
    }
  }

  if (sortable.querySelector('.handle')) {
    options.handle = '.handle'
  }

  return new Sortable(sortable, options)
})

document.addEventListener('DOMContentLoaded', () => {
  const textArea = document.querySelector('#post_content, #story_content')

  if (!textArea) {
    return
  }

  ClassicEditor
    .create(textArea, {
      simpleUpload: {
        uploadUrl: '/photos'
      },
      image: {
        toolbar: [
          'toggleImageCaption',
          'imageTextAlternative'
        ]
      }
    })
    .then(editor => {
      console.log(editor);
    })
    .catch(error => {
      console.error(error);
    });
})
